@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Noto+Sans:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
}

body, html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Noto Sans', 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 16px;
  background: #090E11;
  color: #ECEDED;
}
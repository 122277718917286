.auth {
	&-form {
		padding: 15px 20px;
		width: 100%;
		max-width: 400px;
		margin: 0 auto;
		border-radius: 5px;
		margin-bottom: 10px;
	}
}

.form {
	display: flex;
	flex-direction: column;
	justify-content: center;

	&-title {
		font-weight: 600;
		font-size: 30px;
		margin-bottom: 5px;
	}

	&-description {
		font-size: 14px;
		margin-bottom: 30px;
		color: #66696B;
	}

	&-group {
		margin-bottom: 15px;
		
		&-text {
			display: block;
			margin-bottom: 7px;
			font-size: 14px;
			color: var(--active-text-color);
		}
	}

	&-control {
		display: inline-flex;
		width: 100%;
		align-items: center;
		border-radius: 5px;
		padding: 13px 20px;
		border: 0;
	}

	&-switcher {
		padding: 0 0 30px;

		&-text {
			width: 100%;
			font-size: 14px;

			a {
				color: white;
			}
		}

		.select-form {
			display: inline-flex;
			border-bottom: 1px solid #FFFFFF;
			cursor: pointer;
		}
	}

	&-submit {
		display: flex;
		justify-content: center;
		align-items: center;
		border: 0;
		cursor: pointer;
		font-size: 15px;
		text-align: center;
		width: 100%;
		background: #15191C;
		justify-content: center;
		border-radius: 5px;
		padding: 13px 20px;
		transition: color 0.1s ease-out;
		color: white;
		font-family: 'Noto Sans', 'Noto Sans JP', sans-serif;

		&-icon {
			margin-right: 5px;
			transition: color 0.1s ease-out;
		}
	}
}

.hidden {
	display: none !important;
}
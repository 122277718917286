.enclosing {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.view-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 100%;
    min-height: 20px;
    flex-shrink: 0;
    flex-grow: 1;
    margin: auto 0;
  }
}

.container {
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.navbar {
  width: 100%;
  height: 50px;
  background: #1D2125;
  display: flex;
  flex-shrink: 0;

  &-menu {
    height: 50px;
    padding: 0;
    margin: 0 -10px;
    list-style: none;
    width: calc(100% + 20px);
    display: flex;
    align-items: center;
    justify-content: center;

    li, a {
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    li {
      a {
        padding: 0 10px;
        text-decoration: none;
        color: #747678;
        font-weight: 500;
        transition: color ease-out 0.2s;

        &.active,
        &:hover {
          color: #FFFFFF;
        }
      }
    }
  }

  &-right {
    margin-left: auto;
  }
}
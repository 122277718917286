.controls {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.languages {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;

  &__item {
    margin: 0 25px;
  }

  &__icon {
    font-size: 12px;
    color: #66696B;
  }
}

.source {
  display: flex;
  width: 100%;
  flex-direction: column;

  &__input {
    border-radius: 10px;
    background: #15191C;
    margin-bottom: 10px;
    padding: 20px;
    min-height: 230px;
    flex-shrink: 0;
    overflow-y: auto;

    pre, span, div, i, b, strike, u, p, table, td, th, tr, tbody, thead {
      margin: 0;
      color: #ECEDED !important;
      background: transparent !important;
      font-family: 'Noto Sans', 'Noto Sans JP', sans-serif !important;
    }
  }
}

.button {
  background: #15191C;
  border: 0px;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  color: #ECEDED;
  font-family: 'Noto Sans', 'Noto Sans JP', sans-serif;
  font-size: 14px;
  transition: background 0.2s ease-out;

  &:hover {
    background: #7961FB;
  }
}
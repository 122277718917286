.title {
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 5px;
}

.subtitle {
  font-size: 14px;
  margin-bottom: 30px;
  color: #66696B;
}

.contact {
  margin: 0;
  padding: 0 0 0 20px;
  list-style: none;

  li {
    width: 100%;
    margin-bottom: 5px;
  }

  li, a {
    display: inline-flex;
    align-items: center;
  }

  &-item {
    text-decoration: none;
    font-size: 15px;
    color: #66696B;
    transition: color 0.3s ease-out;

    &:hover {
      color: #FFF;
    }

    &-icon {
      margin-right: 8px;
    }

    &-text {

    }
  }
}